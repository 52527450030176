







































import { Component, Vue } from "vue-property-decorator";
import GA from "@/app/modules/_global/classes/Analytics";

type Messages = {
  success: null | string;
  errors: null | object;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    ForgotPasswordForm: () => import("@/app/modules/auth/components/ForgotPasswordForm.vue")
  }
})
export default class ForgotPasswordPage extends Vue {
  constructor() {
    super();

    GA.addCustomDimension(1, `Unauthenticated user`);
    GA.trackView("Forgot password");
  }

  login: string = "" as string;

  messages: Messages = {
    success: null,
    errors: null
  };

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  forgotPassword() {
    this.$store
      .dispatch("auth/forgotPassword", { login: this.login })
      .then(({ data }) => {
        this.login = "";
        this.messages = {
          success: data.message,
          errors: null
        };
      })
      .catch(error => {
        this.messages = {
          success: null,
          errors: error.response.data.errors
        };
      });
  }
}
